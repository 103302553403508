import MaxWidthWrapper from './../components/MaxWidthWrapper';
import React, { useState, useEffect, useMemo } from 'react';
import {
  useAddWishListMutation,
  useGetProductQuery,
  useGetRecipeQuery,
  usePostCartMutation,
} from '../store/slices/api.slice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import taikezProductImage from '../images/default_image.png';
import CategoryPhoto from '../images/pages/category_photo.png';
import CartBtn from '../components/CartBtn';

const RecipeDetails = () => {
  const { recipeSlug } = useParams<any>();

  const { data: recipe, isLoading, isSuccess } = useGetRecipeQuery(recipeSlug);
  

  const recipeDetails: any = useMemo(() => {
    if (isLoading || isSuccess == false) {
      return {};
    } else {
      return recipe.data;
    }
  }, [isLoading, recipeSlug]);

  // breadcrumbs
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: recipeSlug ?? '', to: `/${recipeSlug}` },
  ];

  return (
    <MaxWidthWrapper className='pb-10'>
      <Breadcrumb items={breadcrumbItems} />
      <section className='flex flex-col items-center justify-center border'>
        <img
          src={CategoryPhoto}
          alt='kitchen utensils'
          width={1000}
          height={1000}
          className='object-cover w-full h-[110px] md:h-full rounded-lg md:rounded-xl'
        />
      </section>
      <section className='flex flex-col justify-between mt-4 gap-y-6 lg:gap-x-4 lg:flex-row'>
        <div className='w-full lg:w-[60%] pb-6'>
          <h2 className='text-base font-semibold capitalize md:text-lg lg:text-xl'>
            {recipeDetails.title}
          </h2>
          <p className='mt-8 text-sm'>
            {recipeDetails.description == ''
              ? recipeDetails.description
              : 'No Description'}
          </p>
        </div>

        <div className='w-full lg:w-[40%]'>
          <h3 className='mb-3 text-sm font-semibold lg:text-base xl:text-lg'>
            Recipes Needed
          </h3>
          <div className='grid grid-cols-4 gap-2'>
            {isSuccess ? (
              recipeDetails.products.map((product: any) => {
                return (
                  <div
                    key={product.id}
                    className='relative flex flex-col flex-wrap w-[150px] bg-white shadow-sm rounded-xl'
                  >
                    {product.stock > 0 ? (
                      <Link
                        to={`/product/${product.sku}`}
                        key={product.id}
                        className='w-full overflow-hidden rounded-xl'
                      >
                        <img
                          src={
                            isSuccess
                              ? product.image.length > 0
                                ? product.image[0].url
                                : taikezProductImage
                              : taikezProductImage
                          }
                          alt={product.name}
                          width={200}
                          height={200}
                          className='object-cover w-full p-1 text-xs shadow md:p-2 rounded-xl product-animate'
                        />
                      </Link>
                    ) : (
                      <div className='relative w-full overflow-hidden cursor-not-allowed rounded-xl'>
                        <img
                          src={
                            isSuccess
                              ? product.image.length > 0
                                ? product.image[0].url
                                : taikezProductImage
                              : taikezProductImage
                          }
                          alt={product.name}
                          width={200}
                          height={200}
                          className='object-cover text-xs w-full md:h-[15rem] lg:h-[16rem] xl:h-[10rem] p-1 md:p-2 rounded-3xl mb-2 shadow product-animate opacity-50'
                        />
                        <div className='absolute inset-0 flex items-center justify-center text-lg font-bold text-white bg-black bg-opacity-50'>
                          Out of Stock
                        </div>
                      </div>
                    )}
                    <div className='product-name-price !mb-0'>
                      <p
                        className={
                          product.stock > 0 ? 'product-name' : 'out-of-stock'
                        }
                      >
                        {product.name}
                      </p>
                      <p
                        className={
                          product.stock > 0 ? 'product-price' : 'out-of-stock'
                        }
                      >
                        {/* ₦ {PriceFormatter(product.price, false)} */}
                      </p>
                    </div>
                    <CartBtn productSku={product.sku} stock={product.stock} />
                  </div>
                );
              })
            ) : (
              <>No Products</>
            )}
          </div>
          {/* Will do a general add cart maybe */}
        </div>
      </section>
    </MaxWidthWrapper>
  );
};

export default RecipeDetails;
