import React from 'react';
import { Link } from 'react-router-dom';
import MaxWidthWrapper from './MaxWidthWrapper';
import whiteLogo from 'src/images/logos/white_logo.svg';

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className='w-full mt-8 shadow h-fit bg-tkzBlack md:mt-0 lg:mt-4'>
      <div
        onClick={handleScrollToTop}
        className='py-3 text-sm font-medium text-center bg-yellow-500 text-tkzBlack hover:cursor-pointer hover:underline'
      >
        Back to top
      </div>
      <MaxWidthWrapper className='flex flex-col-reverse h-full divide-y divide-green-900 lg:justify-between lg:flex-row lg:py-12 gap-y-12 lg:gap-y-0 lg:px-10 xl:px-20 divide-dashed lg:divide-none'>
        {/* left section */}
        <div className='hidden footer-div lg:block'>
          <h1 className='footer-heading'>
            Lets <br /> keep <br /> in touch!
          </h1>
          <div className='flex items-center lg:items-start gap-y-3 gap-x-4'>
            <Link to='/' className='text-neutral-100 hover:text-green-300'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                viewBox='0 0 20 20'
              >
                <path
                  fill='currentColor'
                  d='M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z'
                />
              </svg>
            </Link>
            <Link to='/' className='text-neutral-100 hover:text-green-300'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M13.808 10.469L20.88 2h-1.676l-6.142 7.353L8.158 2H2.5l7.418 11.12L2.5 22h1.676l6.486-7.765L15.842 22H21.5l-7.693-11.531Zm-2.296 2.748l-.752-1.107L4.78 3.3h2.575l4.826 7.11l.751 1.107l6.273 9.242h-2.574l-5.12-7.541Z'
                />
              </svg>
            </Link>
            <Link to='/' className='text-neutral-100 hover:text-green-300'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  d='M13.028 2.001a78.82 78.82 0 0 1 2.189.022l.194.007c.224.008.445.018.712.03c1.064.05 1.79.218 2.427.465c.66.254 1.216.598 1.772 1.154a4.908 4.908 0 0 1 1.153 1.771c.247.637.415 1.364.465 2.428c.012.266.022.488.03.712l.006.194a79 79 0 0 1 .023 2.188l.001.746v1.31a78.836 78.836 0 0 1-.023 2.189l-.006.194c-.008.224-.018.445-.03.712c-.05 1.064-.22 1.79-.466 2.427a4.884 4.884 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-.267.012-.488.022-.712.03l-.194.006a79 79 0 0 1-2.189.023l-.746.001h-1.309a78.836 78.836 0 0 1-2.189-.023l-.194-.006a60.64 60.64 0 0 1-.712-.03c-1.064-.05-1.79-.22-2.428-.466a4.89 4.89 0 0 1-1.771-1.153a4.904 4.904 0 0 1-1.154-1.772c-.247-.637-.415-1.363-.465-2.427a74.367 74.367 0 0 1-.03-.712l-.005-.194A79.053 79.053 0 0 1 2 13.028v-2.056a78.82 78.82 0 0 1 .022-2.188l.007-.194c.008-.224.018-.446.03-.712c.05-1.065.218-1.79.465-2.428A4.88 4.88 0 0 1 3.68 3.68a4.897 4.897 0 0 1 1.77-1.155c.638-.247 1.363-.415 2.428-.465l.712-.03l.194-.005A79.053 79.053 0 0 1 10.972 2h2.056Zm-1.028 5A5 5 0 1 0 12 17a5 5 0 0 0 0-10Zm0 2A3 3 0 1 1 12.001 15a3 3 0 0 1 0-6Zm5.25-3.5a1.25 1.25 0 0 0 0 2.498a1.25 1.25 0 0 0 0-2.5Z'
                />
              </svg>
            </Link>
            <Link to='tel:09019077479' className='text-sm text-white'>
              09019077479
            </Link>
          </div>
        </div>

        {/* center section */}
        <div className='footer-div lg:!items-center lg:!w-full pb-6'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 16 16'
            className='text-yellow-500'
          >
            <path
              fill='currentColor'
              d='M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1z'
            />
          </svg>
          <div className='md:px-10 lg:px-[8%] mt-3 space-y-6'>
            <p className='text-sm text-center text-green-100 md:px-24'>
              Nature`s prescription for a healthy life: indulge in the goodness
              of organic farm produce, the original and most potent medicine for
              your well-being.
            </p>
            <div className='flex items-center justify-center w-full gap-x-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                className='text-green-100'
              >
                <path
                  fill='currentColor'
                  fillRule='evenodd'
                  d='M.5 10a.5.5 0 0 1 .5-.5h18a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5Z'
                  clipRule='evenodd'
                />
              </svg>
              <img
                src={whiteLogo}
                alt='Taikez ecommerce logo'
                width={300}
                height={300}
                className='w-fit h-[30px]'
              />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                className='text-green-100'
              >
                <path
                  fill='currentColor'
                  fillRule='evenodd'
                  d='M.5 10a.5.5 0 0 1 .5-.5h18a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5Z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
          </div>

          <div className='pt-4 w-fit'>
            <ul className='flex flex-col items-center justify-center mt-6 gap-y-4'>
              <li className='text-sm font-medium text-green-100 capitalize'>
                All rights reserved.
              </li>
              <p className='text-neutral-100'>&copy; 2023 Taikez</p>
            </ul>
          </div>
        </div>

        {/* right section */}
        <div className='footer-div lg:w-[50%] lg:!items-end gap-y-5'>
          <div className='flex flex-wrap space-x-6'>
            <Link to='/about-us' className='footer-right-link'>
              About Us
            </Link>
            <Link to='/cookie-notice' className='footer-right-link'>
              Cookie notice
            </Link>
            <Link
              to='/customer-support'
              className='text-right footer-right-link'
            >
              FAQs
            </Link>
          </div>
          <div className='flex flex-wrap space-x-2'>
            <Link to='/return-refund-policy' className='footer-right-link'>
              Refund & Return policy
            </Link>
            <Link to='/shipping-policy' className='footer-right-link'>
              Shipping policy
            </Link>
          </div>
          <div className='flex flex-wrap space-x-6'>
            <Link to='/privacy-policy' className='footer-right-link'>
              Privacy notice
            </Link>
            <Link to='/terms-and-conditions' className='footer-right-link'>
              Terms and conditions
            </Link>
          </div>

          <div className=''>
            <h3 className='mb-2 text-xs font-medium text-white'>
              Get notified with our tailored newsletters!
            </h3>
            <div className='relative'>
              <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  className='text-gray-400'
                >
                  <path
                    fill='currentColor'
                    d='M19.435 4.065H4.565a2.5 2.5 0 0 0-2.5 2.5v10.87a2.5 2.5 0 0 0 2.5 2.5h14.87a2.5 2.5 0 0 0 2.5-2.5V6.565a2.5 2.5 0 0 0-2.5-2.5Zm-14.87 1h14.87a1.489 1.489 0 0 1 1.49 1.39c-2.47 1.32-4.95 2.63-7.43 3.95a6.172 6.172 0 0 1-1.06.53a2.083 2.083 0 0 1-1.67-.39c-1.42-.75-2.84-1.51-4.25-2.26c-1.14-.6-2.3-1.21-3.44-1.82a1.491 1.491 0 0 1 1.49-1.4Zm16.37 12.37a1.5 1.5 0 0 1-1.5 1.5H4.565a1.5 1.5 0 0 1-1.5-1.5V7.6c2.36 1.24 4.71 2.5 7.07 3.75a5.622 5.622 0 0 0 1.35.6a2.872 2.872 0 0 0 2-.41c1.45-.76 2.89-1.53 4.34-2.29c1.04-.56 2.07-1.1 3.11-1.65Z'
                  />
                </svg>
              </div>
              <input
                type='text'
                id='input-group-1'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-xs lg:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 '
                placeholder='enter email address'
              />
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </footer>
  );
};

export default Footer;
