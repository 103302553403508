import Breadcrumb from 'src/components/Breadcrumb';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const TermsConditions = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Terms and Conditions', to: '' },
  ];

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Terms and Conditions
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            Welcome to Taikez! These Terms and Conditions govern your use of our
            website and services. By accessing or using our website, you agree
            to comply with and be bound by these terms. If you do not agree with
            any part of these terms, please do not use our website.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            1. Acceptance of Terms
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            By using Taikez.com.ng, you confirm that you are at least 18 years
            old or have the consent of a legal guardian to use this website. You
            also agree to comply with all applicable laws and regulations.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            2. Account Registration
          </h4>

          <ul className='list-decimal'>
            <li>
              To access certain features of our website, you may need to create
              an account.
            </li>

            <li>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account.
            </li>
            <li>
              You agree to provide accurate and complete information during
              registration and to update it as necessary.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            3. Product Information
          </h4>

          <ul className='list-decimal'>
            <li>
              We strive to provide accurate descriptions, images, and pricing
              for all products. However, we do not guarantee that all
              information is error-free.
            </li>

            <li>
              Prices are subject to change without notice. All prices are listed
              in Nigerian Naira (NGN) unless otherwise stated.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            4. Orders and Payments
          </h4>

          <ul className='list-decimal'>
            <li>
              By placing an order, you agree to pay the specified price for the
              product(s) and any applicable taxes, shipping fees, or other
              charges.
            </li>

            <li>
              We accept payment through secure methods such as credit/debit
              cards, bank transfers, and other approved payment gateways.
            </li>

            <li>
              Orders are subject to availability. If a product is out of stock,
              we will notify you and provide a refund or alternative options.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            5. Shipping and Delivery
          </h4>

          <ul className='list-decimal'>
            <li>
              We aim to process and ship orders within [Insert Number] business
              days.
            </li>

            <li>
              Delivery times may vary depending on your location and the
              shipping method selected.
            </li>

            <li>
              You are responsible for providing accurate shipping information.
              We are not liable for delays or losses caused by incorrect
              addresses.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            6. Returns and Refunds
          </h4>

          <ul className='list-decimal'>
            <li>
              If you are not satisfied with your purchase, you may return the
              product within 24 hours days of delivery, provided it is in its
              original condition.
            </li>

            <li>
              Refunds will be processed within 3-7 business days after we
              receive and inspect the returned product.
            </li>

            <li>
              Shipping costs for returns are the responsibility of the customer
              unless the return is due to a defect or error on our part.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            7. Intellectual Property
          </h4>

          <ul className='list-decimal'>
            <li>
              All content on Taikez.com.ng, including text, images, logos, and
              designs, is the property of Taikez or its licensors and is
              protected by intellectual property laws.
            </li>

            <li>
              You may not reproduce, distribute, or use any content from our
              website without prior written permission.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            8. User Conduct
          </h4>

          <p className='mb-4 text-lg font-bold leading-tight'>
            You agree not to:
          </p>
          <ul className='list-decimal'>
            <li>Use our website for any illegal or unauthorized purpose.</li>

            <li>
              Attempt to gain unauthorized access to our systems or interfere
              with the website's functionality.
            </li>

            <li>
              Post or transmit any harmful, offensive, or misleading content.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            9. Limitation of Liability
          </h4>

          <ul className='list-decimal'>
            <li>
              Taikez.com.ng is provided on an "as-is" and "as-available" basis.
              We do not guarantee that the website will be error-free or
              uninterrupted.
            </li>

            <li>
              We are not liable for any indirect, incidental, or consequential
              damages arising from your use of our website or products.
            </li>

            <li>
              Post or transmit any harmful, offensive, or misleading content.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            10. Indemnification
          </h4>

          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            You agree to indemnify and hold Taikez.com.ng, its affiliates, and
            employees harmless from any claims, damages, or losses arising from
            your use of the website or violation of these terms.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            11. Termination
          </h4>

          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We reserve the right to suspend or terminate your access to our
            website at any time, without notice, for any reason, including
            violation of these terms.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            12. Governing Law
          </h4>

          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            These Terms and Conditions are governed by the laws of the Federal
            Republic of Nigeria. Any disputes arising from your use of our
            website shall be resolved in the courts of Nigeria.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            13. Changes to These Terms
          </h4>

          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We may update these Terms and Conditions from time to time. Any
            changes will be posted on this page, and your continued use of the
            website constitutes acceptance of the updated terms.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Acknowledgment
          </h4>

          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            By using Taikez.com.ng, you acknowledge that you have read,
            understood, and agreed to these Terms and Conditions.
          </p>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default TermsConditions;
