import CartService from '../config/services/cart.service';
import React, { useState } from 'react';
import AlertModal from './AlertModal';
import { useNavigate } from 'react-router-dom';
import { usePostCartMutation } from '../store/slices/api.slice';
import CookieHelper from '../config/utils/cookie.helper';
import { AppKeys } from '../config/utils/constants';

type CartBtnProps = {
  productSku: string;
  stock: number;
  price?: any
  name?: string
};

const CartBtn: React.FC<CartBtnProps> = ({ productSku, stock, price, name }) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState({ show: false, msg: '' });
  const [postCart, { isLoading: loadingCart }] = usePostCartMutation()


  const addToCart = async (sku: any) => {
    let catalogue = CookieHelper.get(AppKeys.CART) || [];
    let existingItem = catalogue.find((item: any) => item.sku === sku);
    const body = {
      catalogue: [
        {
          sku: sku,
          quantity: '1'
        }
      ]
    }
    if (existingItem) {
      existingItem.quantity = +existingItem.quantity + 1;  
    } else {
    catalogue.push({
      sku: sku,
      quantity: 1,
      price: price,
      name: name
    })
  }
    try {
      const responseData = await postCart(body).unwrap();
      const { status, message } = responseData;
      if (status === true) {
        setShowAlert({ show: true, msg: message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      } else if (message === 'Error occurred, validation token not found') {
        CookieHelper.set(AppKeys.CART, 
          catalogue, AppKeys.COOKIE_EXPIRY_PERIOD);
        } else {
          setShowAlert({ show: true, msg: message });
          setTimeout(
            () => setShowAlert((prevState) => ({ ...prevState, show: false })),
            4000
          );
        }
      } catch (error: any) {
        const { message } = error.data
        if (message == 'Error occurred, validation token not found') {
        
        CookieHelper.set(AppKeys.CART, catalogue, AppKeys.COOKIE_EXPIRY_PERIOD);

        // navigate('/login')
      } else {
        setShowAlert({ show: true, msg: 'Network Error, Check your Internet' });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      }
    }
  };

  return (
    <div className='flex justify-center'>
      {stock > 0 ? (
        <button
          onClick={() => {
            addToCart(productSku);
          }}
          className='relative top-4 z-2 mx-auto rounded-full bg-green-600 text-white hover:bg-green-700 px-5 py-.5'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 2048 1792'
            className='w-[1rem] md:w-[1.5rem]'
          >
            <path
              fill='currentColor'
              d='M1920 768q53 0 90.5 37.5T2048 896t-37.5 90.5t-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30H384q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5T0 896t37.5-90.5T128 768zM485 1568q26-2 43.5-22.5T544 1499l-32-416q-2-26-22.5-43.5T443 1024t-43.5 22.5T384 1093l32 416q2 25 20.5 42t43.5 17zm411-64v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m384 0v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m352 5l32-416q2-26-15.5-46.5T1605 1024t-46.5 15.5t-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42M476 292l-93 412H251l101-441q19-88 89-143.5T601 64h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19H832q-26 0-45-19t-19-45H601q-45 0-79.5 28T476 292'
            />
          </svg>
        </button>
      ) : (
        <div className='relative top-4 z-2 mx-auto rounded-full bg-green-600 text-white hover:bg-green-700 px-5 py-.5 opacity-50 cursor-not-allowed'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 2048 1792'
            className='w-[1rem] md:w-[1.5rem]'
          >
            <path
              fill='currentColor'
              d='M1920 768q53 0 90.5 37.5T2048 896t-37.5 90.5t-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30H384q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5T0 896t37.5-90.5T128 768zM485 1568q26-2 43.5-22.5T544 1499l-32-416q-2-26-22.5-43.5T443 1024t-43.5 22.5T384 1093l32 416q2 25 20.5 42t43.5 17zm411-64v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m384 0v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m352 5l32-416q2-26-15.5-46.5T1605 1024t-46.5 15.5t-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42M476 292l-93 412H251l101-441q19-88 89-143.5T601 64h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19H832q-26 0-45-19t-19-45H601q-45 0-79.5 28T476 292'
            />
          </svg>
        </div>
      )}
      {showAlert.show && (
        <AlertModal
          msg={showAlert.msg}
          click={() =>
            setShowAlert((prevState) => ({ ...prevState, show: false }))
          }
        />
      )}
    </div>
  );
};

export default CartBtn;
