import Breadcrumb from 'src/components/Breadcrumb';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Privacy Policy', to: '' },
  ];

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Privacy Policy
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            At Taikez, we are committed to protecting your privacy and ensuring
            the security of your personal information. This Privacy Policy
            explains how we collect, use, disclose, and safeguard your
            information when you visit our website, use our services, or
            interact with us. By using our website, you consent to the practices
            described in this policy.
          </p>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            1. Information We Collect
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            At Taikez, we are committed to protecting your privacy and ensuring
            the security of your personal information. This Privacy Policy
            explains how we collect, use, disclose, and safeguard your
            information when you visit our website, use our services, or
            interact with us. By using our website, you consent to the practices
            described in this policy.
          </p>

          <h4 className='mt-4 text-lg font-bold leading-tight'>
            We may collect the following types of information:
          </h4>
          <ul className='list-item'>
            <li>
              1. Personal Information: - Name, email address, phone number, and
              shipping/billing address. - Payment information (e.g.,
              credit/debit card details) for processing transactions. - Account
              credentials (e.g., username and password) for registered users.
            </li>
            <li>
              2. Transaction Information: - Details about your purchases, order
              history, and payment methods.
            </li>
            <li>
              3. Technical Information: - IP address, browser type, device
              information, and operating system. - Cookies and similar
              technologies (see our [Cookie
              Notice](https://taikez.com.ng/cookie-notice) for more details).
            </li>
            <li>
              4. Usage Data: Information about how you interact with our
              website, such as pages visited, time spent on the site, and
              clickstream data.
            </li>
            <li>
              5. Marketing Preferences: Your preferences for receiving marketing
              communications from us.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mt-8 mb-4 text-lg font-bold leading-tight'>
            2. How We Use Your Information
          </h4>
          <h5>We use your information for the following purposes:</h5>
          <ul className='list-item'>
            <li>
              1. To Provide Our Services**: - Process and fulfill your orders. -
              Manage your account and provide customer support. - Communicate
              with you about your orders, inquiries, and requests.
            </li>
            <li>
              2. To Improve Our Website: - Analyze website usage and user
              behavior to enhance functionality and user experience. -
              Personalize your experience by showing relevant products and
              content.
            </li>
            <li>
              3. For Marketing Purposes: - Send you promotional offers,
              newsletters, and updates about our products and services (with
              your consent). - Display targeted advertisements based on your
              interests.
            </li>
            <li>
              4. For Legal and Security Purposes: - Comply with legal
              obligations and enforce our terms and conditions. - Protect
              against fraud, unauthorized transactions, and other illegal
              activities.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mt-8 mb-4 text-lg font-bold leading-tight'>
            3. How We Share Your Information We may share your information with
            the following parties:
          </h4>
          <h5>We use your information for the following purposes:</h5>
          <ul className='list-item'>
            <li>
              1. Service Providers: - Third-party vendors who assist us with
              payment processing, shipping, marketing, and website analytics.
            </li>
            <li>
              2. Business Partners: - Trusted partners who collaborate with us
              to provide joint services or promotions.
            </li>
            <li>
              3. Legal Authorities: - When required by law or to protect our
              rights, property, or safety.
            </li>
            <li>
              4. With Your Consent: - We may share your information with third
              parties if you give us explicit consent.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mt-8 mb-4 text-lg font-bold leading-tight'>
            4. Data Security
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction. These measures include: -
            Encryption of sensitive data (e.g., payment information). - Regular
            security audits and vulnerability assessments. - Restricted access
            to personal information on a need-to-know basis.
          </p>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mt-8 mb-4 text-lg font-bold leading-tight'>
            5. Your Rights:
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction. These measures include: -
            Encryption of sensitive data (e.g., payment information). - Regular
            security audits and vulnerability assessments. - Restricted access
            to personal information on a need-to-know basis.
          </p>
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction. These measures include: -
            Encryption of sensitive data (e.g., payment information). - Regular
            security audits and vulnerability assessments. - Restricted access
            to personal information on a need-to-know basis.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <ul className='list-item'>
            <li>
              1. Access: Request a copy of the personal information we hold
              about you.
            </li>
            <li>
              2. Correction: Request corrections to inaccurate or incomplete
              information.
            </li>
            <li>
              3. Deletion: Request the deletion of your personal information
              under certain conditions.
            </li>
            <li>
              4. Objection: Object to the processing of your information for
              marketing or other purposes.
            </li>
            <li>
              5. Withdraw Consent: Withdraw your consent for processing your
              information at any time. To exercise these rights, please contact
              us using the details provided below.
            </li>
            <li>
              6. Data Retention: We retain your personal information only for as
              long as necessary to fulfill the purposes outlined in this policy
              or as required by law. After this period, your data will be
              securely deleted or anonymized.
            </li>
            <li>
              7. International Transfers: If we transfer your personal
              information outside Nigeria, we will ensure that adequate
              safeguards are in place to protect your data in accordance with
              applicable laws.
            </li>
            <li>
              8. Third-Party Links: Our website may contain links to third-party
              websites. We are not responsible for the privacy practices or
              content of these websites. We encourage you to review their
              privacy policies before providing any personal information.
            </li>
            <li>
              9. Children's Privacy: Our website is not intended for children
              under the age of
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mt-8 mb-4 text-lg font-bold leading-tight'>
            13. We do not knowingly collect or solicit personal information from
            children. If we become aware that we have collected personal
            information from a child, we will delete it immediately.
          </h4>
          <ul className='list-item'>
            <li>
              10. Changes to This Privacy Policy: We may update this Privacy
              Policy from time to time to reflect changes in our practices or
              legal requirements. We will notify you of any significant changes
              by posting the updated policy on our website or sending you a
              notification.
            </li>
          </ul>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default PrivacyPolicy;
