import Breadcrumb from 'src/components/Breadcrumb';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const CookieNotice = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Cookie Notice', to: '' },
  ];

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Cookie Notice
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            At Taikez, we use cookies and similar technologies to enhance your
            browsing experience, improve our website's functionality, and
            provide you with personalized services. This Cookie Notice explains
            what cookies are, how we use them, and how you can manage your
            preferences.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            What Are Cookies?
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            Cookies are small text files that are stored on your device
            (computer, smartphone, or tablet) when you visit a website. They
            help us recognize your device and remember your preferences, such as
            language settings, login details, and other preferences.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            How We Use Cookies
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We use cookies for the following purposes:
            <h6>1. Essential Cookies</h6>
            These cookies are necessary for the website to function properly.
            They enable core functionalities such as page navigation, secure
            login, and shopping cart operations. Without these cookies, our
            website cannot operate effectively.
            <h6>2. Performance Cookies:</h6>
            These cookies help us understand how visitors interact with our
            website by collecting information about page visits, traffic
            sources, and user behavior. This data helps us improve the
            performance and usability of our website.
            <h6>3. Functional Cookies:</h6>
            These cookies allow our website to remember your preferences (e.g.,
            language, region) and provide enhanced, personalized features.
            <h6>4. Advertising Cookies:</h6>
            These cookies are used to deliver relevant advertisements to you
            based on your interests. They also help us measure the effectiveness
            of our marketing campaigns.
            <h6>5. Third-Party Cookies:</h6>
            We may use third-party services (e.g., Google Analytics, social
            media platforms) that place cookies on your device to provide
            analytics, advertising, and social media integration.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Your Cookie Preferences
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            You have the right to manage or disable cookies at any time. Most
            web browsers allow you to control cookies through their settings.
            Here's how you can manage cookies:
            <h6 className='font-bold'>Browser Settings:</h6>
            You can configure your browser to accept, reject, or delete cookies.
            For instructions, visit the "Help" section of your browser.
            <h6 className='font-bold'>Opt-Out Tools</h6>
            <h5>You can use tools like the:</h5>
            <ul className='list-decimal w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
              <li>Digital Advertising Alliance's (DAA)</li>
              <li>
                Opt-Out Tool or the Network Advertising Initiative (NAI) Opt-Out
                Tool
              </li>
              <li>
                Opt-Out Tool or the Network Advertising Initiative (NAI) Opt-Out
                Tool to manage third-party advertising cookies.
              </li>
            </ul>
            <h5 className='font-bold'>Cookie Consent Banner:</h5>
            When you first visit our website, you will see a cookie consent
            banner where you can accept or reject non-essential cookies. Please
            note that disabling certain cookies may affect the functionality of
            our website and your user experience.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Why We Use Cookies
          </h4>
          <h5>Cookies help us:</h5>
          <ul className='list-disc w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            <li>Provide a seamless and personalized shopping experience. </li>
            <li>
              Analyze website traffic and user behavior to improve our services.
            </li>
            <li>
              Deliver targeted advertisements that are relevant to your
              interests.
            </li>
            <li>Ensure the security and efficiency of our website.</li>
          </ul>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Changes to This Cookie Notice
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            From time to time to reflect changes in our practices or legal
            requirements. We encourage you to review this notice periodically to
            stay informed about how we use cookies.
          </p>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default CookieNotice;
