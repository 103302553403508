import Breadcrumb from 'src/components/Breadcrumb';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const ReturnRefundPolicy = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Return Policy', to: '' },
  ];

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Return Policy
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            The return policy does not apply to food Items delivered in Taikez
            e-commerce as the company deals with sales of perishable farm
            products e.g. fruits and vegetables, fish, meat, palm oil, etc. but
            you may request the return of food items that are defective and
            contaminated at the point of delivery and which order must be
            cancelled immediately at the point of delivery to be returned.
            Refund of returned goods would be made within 3 working days.
            However, if a customer/consumer changes an initial order at the
            point of delivery. He/she will be responsible for the cost of
            shipping/transportation of the new order. We offer an immediate
            return policy at the point of delivery for all unused and unopened
            products. Customers may return items for a full refund provided they
            are in their original condition and packaging. Return shipping costs
            are the customer's responsibility. Please fill out our return form
            and send it along with the product and your receipt through our
            delivery contact. To be eligible for a return, your Items must be
            unused, unopened and in the same condition that you received them we
            do not do an exchange of items and proof of payment for items
            purchased is also essential. If our products are damaged, rotten or
            contaminated in any way, please contact us immediately with proofs
            and not less than 5 minutes after delivery of same and the matter
            would be resolved within 3 working days after proper investigation
            to either refund/replace the same.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Refund Policy
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            We are pleased to inform you that refunds of payment can only be
            made for food items ordered and cancelled before delivery as there
            will be refund or return for items already delivered. If we are
            unable to provide for a food items ordered at requested on time,
            your order will be cancelled automatically and payment will be
            refunded back to your wallet and you can there after placing another
            order. Please note that No refund will be considered taking into
            account the availability of food items, logistics and reasonable
            ground except an order is cancelled before delivery. Payment is
            required at the conclusion of an Order and all payments will be
            processed through the marketplace portal and can be made by Visa,
            Mastercard or Visa Debit. Your acceptance below represents
            pre-authorization to use your bank card to charge for fees and
            services. Receipts will be emailed once payment has been processed.
            It is the Taikez marketplace's responsibility to ensure the
            provisions of their insurance for the delivery of items, Receipts
            for payments of goods ordered will be issued in the name(s) of the
            client receiving such.
          </p>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default ReturnRefundPolicy;
