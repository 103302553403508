import { useEffect, useState } from 'react';
import ProductGridSection from '../components/ProductGridSection';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Slider from '../components/Slider';
import FeaturedCat from '../components/FeaturedCat';
import Hero from '../components/Hero';
import RecipeSection from '../components/RecipeSection';
import { RECIPE_DATA } from 'src/data/recipeData';
import { useGetCompositeProductsQuery } from '../store/slices/api.slice';

const Home = () => {
  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetCompositeProductsQuery('');

  const [showCookieNotice, setShowCookieNotice] = useState(false);

  useEffect(() => {
    const hasSeenNotice = sessionStorage.getItem('hasSeenCookieNotice');

    if (!hasSeenNotice) {
      setShowCookieNotice(true);

      const timer = setTimeout(() => {
        setShowCookieNotice(false);

        sessionStorage.setItem('hasSeenCookieNotice', 'true');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      {showCookieNotice && (
        <div className='fixed z-50 p-4 text-white bg-gray-800 rounded-lg shadow-lg bottom-4 left-4 right-4'>
          <p>
            We use cookies to enhance your browsing experience. By continuing to
            use this site, you agree to our use of cookies.
          </p>
        </div>
      )}
      <MaxWidthWrapper>
        <Slider />
        <Hero />
        <FeaturedCat title='Featured Categories' />
        <ProductGridSection
          loading={isLoading}
          isSuccess={isSuccess}
          productData={isSuccess ? products.data : []}
        />
      </MaxWidthWrapper>
      <RecipeSection
        title={RECIPE_DATA[0].title}
        link={RECIPE_DATA[0].link}
        bannerSrc={RECIPE_DATA[0].bannerSrc}
        recipes={RECIPE_DATA[0].recipes}
      />
    </div>
  );
};

export default Home;
