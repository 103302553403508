import { useEffect, useState } from 'react';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import ProductCard from './../components/ProductCard';
import categoryPhoto from '../images/pages/category_photo.png';

import ProductDetailService from '../config/services/productDetail.service';
import { useLocation, useParams } from 'react-router-dom';
import { useSearchProductMutation } from '../store/slices/api.slice';
import FeaturedCat from '../components/FeaturedCat';

const AllProducts = () => {
  const [products, setProducts] = useState<any>([]);
  const [productCount, setProductCount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const searchItem = params.search;

  const [searchProduct, { isSuccess }] = useSearchProductMutation();

  useEffect(() => {
    searchProductName();
  }, [params]);

  const searchProductName = async () => {
    setLoading(true);
    const searchData: any = {
      'product.name': searchItem,
    };
    const response = await searchProduct(searchData);
    setLoading(false);
    setProducts(response.data.data);
    setProductCount(response.data.count);
  };

  return (
    <div className='mb-16'>
      <MaxWidthWrapper className='py-8 bg-neutral-100'>
        <div className='w-full'>
          <img
            src={categoryPhoto}
            alt='pepper, corn, groundnut, spices and lemon on the table'
            width={1000}
            height={500}
            className='object-cover w-full rounded-xl'
          />
        </div>
      </MaxWidthWrapper>

      <section className='flex flex-col justify-between pt-4 lg:ps-10 xl:ps-20 '>
        <h3 className='text-lg font-semibold capitalize ms-4'>
          {searchItem} ({productCount})
        </h3>
        <div className='px-3 md:px-6 py-5 lg:ps-0 xl:pe-20 lg:w-[70%] xl:w-[100%]'>
          {!loading ? (
            productCount > 0 ? (
              <ProductCard products={products} isSuccess={isSuccess} />
            ) : (
              <div>
                <p className='text-sm lg:text-base text-center'>
                  Sorry...we currently don't have "<b>{searchItem}</b>" in
                  stock.
                </p>
                <FeaturedCat title='Check for products in these categories' />
              </div>
            )
          ) : (
            <p className='text-base text-center'>Loading...</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default AllProducts;