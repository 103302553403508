import Breadcrumb from 'src/components/Breadcrumb';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const ShippingPolicy = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Refund Policy', to: '' },
  ];

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[60%]'>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Shipping Policy
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            All orders placed before 2:00 PM Monday to Friday are processed and
            dispatched the same day, All orders placed after will be dispatched
            the next day. All orders placed during the weekend or on a public
            holiday will be sent from our warehouse on Monday or on the next
            business day. Orders sent will be delivered within 48 hours within
            Ogun State, 72 hours for other States and 1 week for outside
            Nigeria. Please note that Minimum of N100,000 worth of Items would
            be delivered at N10,000 and N150,000 worth of food items will be
            delivered at N20,000. For International Orders, Your package may be
            subject to import duties and taxes. You, as the customer, are
            responsible for paying those fees. We recommend that you check with
            your local customs office before placing an order on our website as
            these fees can sometimes be significant and we are unable to
            calculate these for you. Tracking Your Order; Once your order has
            been dispatched, we will send you a confirmation email with tracking
            information. You will be able to track your package directly on the
            carrier's website. Returns, Refunds, and Exchanges; We want you to
            be completely happy with your purchase - please read our return &
            refund policy for detailed information about our processes.
          </p>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default ShippingPolicy;
